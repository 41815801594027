import request from '@/utils/request'

// 查询徽章分类配置列表
export function listBadgeType(query) {
  return request({
    url: '/config/badge/type/list',
    method: 'get',
    params: query
  })
}

// 查询徽章分类配置详细
export function getBadgeType(id) {
  return request({
    url: '/config/badge/type/' + id,
    method: 'get'
  })
}

// 新增徽章分类配置
export function addBadgeType(data) {
  return request({
    url: '/config/badge/type',
    method: 'post',
    data: data
  })
}

// 修改徽章分类配置
export function updateBadgeType(data) {
  return request({
    url: '/config/badge/type',
    method: 'put',
    data: data
  })
}

// 删除徽章分类配置
export function delBadgeType(id) {
  return request({
    url: '/config/badge/type/' + id,
    method: 'delete'
  })
}
